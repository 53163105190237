<template>
  <SearchRedirect :search-type="'share'" />
</template>

<script>
import SearchRedirect from '@/views/search/SearchRedirect.vue'

export default {
  name: 'Share',
  components: {
    SearchRedirect,
  },
}
</script>

<style scoped>

</style>
